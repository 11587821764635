import React from "react"
import Layout from "@/components/layout"
import SearchContextProvider from "@/context/search-context"
import MenuContextProvider from "@/context/menu-context"
import logoBertPro from '@/images/lp/logo-bertoni-pro.svg'

const LeadsPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <Layout PageTitle="Leads">
        <div style={{ background: '#b2e5ff', textAlign: 'center' }}>
          <img src={logoBertPro} style={{ marginTop: 40 }} />
          <iframe src="https://forms.monday.com/forms/embed/6f118c79b9647149ab80d88f203ef198?r=use1" width="100%" height="2000" style={{ border: 'none' }} />
        </div>
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default LeadsPage;
